import React from "react";

import {
  filterArray,
  get6hPeriodBoundary,
  maxNrRecievedNrExpected
} from "../../../utils/general";

import {
  fetchData,
  URL_SYNOP_OBSERVATIONS_TIMESERIES,
  URL_SHIP_OBSERVATIONS_TIMESERIES,
  URL_BUOY_OBSERVATIONS_TIMESERIES,
  URL_SHIP_AGGREGATED_OBSERVATIONS_TIMESERIES,
  URL_BUOY_AGGREGATED_OBSERVATIONS_TIMESERIES,
  URL_SYNOP_AGGREGATED_OBSERVATIONS_TIMESERIES,
  URL_TEMP_OBSERVATIONS_TIMESERIES,
  URL_TEMP_AGGREGATED_OBSERVATIONS_SIXHOUR_TIMESERIES,
  URL_TEMP_AGGREGATED_OBSERVATIONS_DAILY_TIMESERIES,
  URL_TEMP_AGGREGATED_OBSERVATIONS_MONTHLY_TIMESERIES
} from "../../../utils/api";

import "./LinkTimeSeries.css";

const LinkTimeSeries = props => {
  // Call API to fetch data for time-series
  const fetchStationData = e => {
    e.preventDefault();
    // open the chart modal
    props.setVisible(true);
    const station = e.target.getAttribute("data-station");
    let dateUntil = e.target.getAttribute("data-date");
    const center = e.target.getAttribute("data-center");
    const level = e.target.getAttribute("data-level");
    let dataY = e.target.getAttribute("data-y"); // i.e RMSE, avg_bg_dep, std_bg_dep, nr_gross_error

    if (level === "Stra") {
      props.onSetLevel("Stratosphere");
    } else if (level === "Trop") {
      props.onSetLevel("Troposphere");
    }

    let periodTypePart = props.selectedPeriodType;
    let chartData = [];
    let timeseriesUrl = "";
    if (props.fileType === "synop") {
      if (
        props.selectedReport === "quality" &&
        props.selectedPeriodType === "six_hour" &&
        !props.combinedMode
      ) {
        timeseriesUrl = `${URL_SYNOP_AGGREGATED_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&period_type=${periodTypePart}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&nb_days=30`;
        const promise1 = fetchData(timeseriesUrl);

        const promise2 = fetchData(
          `${URL_SYNOP_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}`
        );

        Promise.all([promise1, promise2])
          .then(function(responses) {
            chartData = responses.map(r => r.data);
            // chartData.push({ "data-y": dataY });
            chartData.push({ "timeseries-url": timeseriesUrl });
            props.onSetChartData(chartData);
          })
          // To improve
          .catch(error => console.log(`Error in executing ${error}`));
      } else if (
        props.selectedReport === "quality" &&
        props.selectedPeriodType === "six_hour" &&
        props.combinedMode
      ) {
        timeseriesUrl = `${URL_SYNOP_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}`;
        fetchData(timeseriesUrl)
          .then(response => {
            chartData.push(response.data);
            chartData.push({ "data-y": dataY });
            chartData.push({ "timeseries-url": timeseriesUrl });
            props.onSetChartData(chartData);
          })
          .catch(error => {
            console.log("ERROR API 2");
          });
      } else {
        // `alert` is a 5-day average of daily values.
        // We request daily values from the API and calculate the 5-day average in JS when preparing the data for the chart
        let nb_months = 1;
        if (
          props.selectedPeriodType === "alert" ||
          props.selectedPeriodType === "daily"
        ) {
          periodTypePart = "daily";
          nb_months = 1;
        } else if (props.selectedPeriodType === "monthly") {
          nb_months = 24;
          periodTypePart = "monthly";
        }
        timeseriesUrl = `${URL_SYNOP_AGGREGATED_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&period_type=${periodTypePart}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&nb_days=30&nb_months=${nb_months}`;
        fetchData(timeseriesUrl)
          .then(response => {
            // for availaibility/combined/surface pressure
            // we need to filter the data first to only keep one of
            // surface pressure and geopotentail per center
            let returnedData = response.data;
            if (
              props.combinedMode &&
              props.selectedReport === "availability" &&
              props.selectedVariable === "110" &&
              props.selectedPeriodType !== "monthly"
            ) {
              returnedData = filterArray(
                returnedData,
                ["center", "date"],
                "nr_received",
                {
                  var_id: 1
                }
              );
            }
            if (
              props.selectedPeriodType === "monthly" &&
              props.selectedReport === "availability"
            ) {
              returnedData = maxNrRecievedNrExpected(returnedData);
            }
            chartData.push(returnedData);
            chartData.push({ "data-y": dataY });
            chartData.push({ "timeseries-url": timeseriesUrl });
            props.onSetChartData(chartData);
          })
          .catch(error => {
            console.log("ERROR API 3");
          });
      }
    } else if (props.fileType === "temp") {
      if (props.selectedPeriodType === "daily") {
        timeseriesUrl = `${URL_TEMP_AGGREGATED_OBSERVATIONS_DAILY_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&level=${level}&nb_days=30`;
        fetchData(timeseriesUrl)
          .then(response => {
            chartData.push(response.data);
            chartData.push({ "data-y": dataY });
            chartData.push({ "timeseries-url": timeseriesUrl });
            props.onSetChartData(chartData);
          })
          .catch(error => {
            console.log("ERROR API TEMP DAILY");
          });
      } else if (props.selectedPeriodType === "monthly") {
        timeseriesUrl = `${URL_TEMP_AGGREGATED_OBSERVATIONS_MONTHLY_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&level=${level}&nb_months=24`;
        fetchData(timeseriesUrl)
          .then(response => {
            chartData.push(response.data);
            chartData.push({ "data-y": dataY });
            chartData.push({ "timeseries-url": timeseriesUrl });
            props.onSetChartData(chartData);
          })
          .catch(error => {
            console.log("ERROR API TEMP Monthly");
          });
      } else if (props.selectedPeriodType === "six_hour") {
        if (
          (props.selectedReport === "quality" && !props.combinedMode) ||
          props.selectedReport === "availability"
        ) {
          // we fetch the individual observations so we need to get
          // the upper boundary of the six-hour period

          dateUntil = get6hPeriodBoundary(dateUntil);
          timeseriesUrl = `${URL_TEMP_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&nb_days=30`;
          fetchData(timeseriesUrl)
            .then(response => {
              chartData.push(response.data);
              chartData.push({ "data-y": dataY });
              chartData.push({ "timeseries-url": timeseriesUrl });
              props.onSetChartData(chartData);
            })
            .catch(error => {
              console.log("TEMP/SIX-HOUR/QUALITY/SIMPLE: ", error);
            });
        } else if (props.selectedReport === "quality" && props.combinedMode) {
          timeseriesUrl = `${URL_TEMP_AGGREGATED_OBSERVATIONS_SIXHOUR_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&level=${level}&nb_days=30`;
          fetchData(timeseriesUrl)
            .then(response => {
              chartData.push(response.data);
              chartData.push({ "data-y": dataY });
              chartData.push({ "timeseries-url": timeseriesUrl });
              props.onSetChartData(chartData);
            })
            .catch(error => {
              console.log("TEMP/SIX-HOUR/QUALITY/COMBINED: ", error);
            });
        }
      }
    } else if (props.fileType === "ship") {
      let nb_months = 1;
      if (props.selectedPeriodType === "daily") {
        periodTypePart = "daily";
        nb_months = 1;
        timeseriesUrl = `${URL_SHIP_AGGREGATED_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&period_type=${periodTypePart}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&nb_days=30&nb_months=${nb_months}`;
      } else {
        timeseriesUrl = `${URL_SHIP_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}`;
      }

      fetchData(timeseriesUrl)
        .then(response => {
          chartData.push(response.data);
          chartData.push({ "data-y": dataY });
          chartData.push({ "timeseries-url": timeseriesUrl });
          props.onSetChartData(chartData);
        })
        .catch(error => {
          console.log("ERROR API 2");
        });
    } else if (props.fileType === "buoy") {
      timeseriesUrl = `${URL_BUOY_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}`;
      fetchData(timeseriesUrl)
        .then(response => {
          chartData.push(response.data);
          chartData.push({ "data-y": dataY });
          chartData.push({ "timeseries-url": timeseriesUrl });
          props.onSetChartData(chartData);
        })
        .catch(error => {
          console.log("ERROR API 2");
        });
    } else if (props.fileType === "marine_surface") {
      let nb_months = 1;
      if (props.stationObject.file_type === "buoy") {
        if (props.selectedPeriodType === "daily") {
          periodTypePart = "daily";
          nb_months = 1;
          timeseriesUrl = `${URL_BUOY_AGGREGATED_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&period_type=${periodTypePart}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&nb_days=30&nb_months=${nb_months}`;
        } else {
          timeseriesUrl = `${URL_BUOY_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}`;
        }      } else {
        if (props.selectedPeriodType === "daily") {
          periodTypePart = "daily";
          nb_months = 1;
          timeseriesUrl = `${URL_SHIP_AGGREGATED_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&report=${props.selectedReport}&period_type=${periodTypePart}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&nb_days=30&nb_months=${nb_months}`;
        } else {
          timeseriesUrl = `${URL_SHIP_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}`;
        }
        // timeseriesUrl = `${URL_SHIP_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&station=${station}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}`;
      }
      dataY = "avg_bg_dep";
      fetchData(timeseriesUrl)
        .then(response => {
          chartData.push(response.data);
          chartData.push({ "data-y": dataY });
          chartData.push({ "timeseries-url": timeseriesUrl });
          console.log("response.data: ", response.data);
          props.onSetChartData(chartData);
          console.log("DATA: ", chartData);
        })
        .catch(error => {
          console.log("ERROR API 2");
        });
    }
  };
  let component;
  console.log(props.type);
  if (props.type === "single") {
    if (
      props.fileType === "ship" ||
      props.fileType === "buoy" ||
      props.fileType === "marine_surface"
    ) {
      component = (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          id="link-chart"
          onClick={fetchStationData}
          href="#"
          data-station={props.data.station}
          data-variable={props.data.variable}
          data-date={props.data.date}
          data-center={props.data.center}
          data-y="bg_dep"
        >
          Time series
        </a>
      );
    } else {
      component = (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          id="link-chart"
          onClick={fetchStationData}
          href="#"
          data-station={props.data.station}
          data-variable={props.data.variable}
          data-date={props.data.date}
          data-center={props.data.center}
        >
          Time series
        </a>
      );
    }
  } else {
    // case 'double'
    component = (
      <div className="ts-container">
        Time series:{" "}
        <div className="link-container">
          <a
            id="link-chart"
            onClick={fetchStationData}
            href="#"
            data-station={props.data.station}
            data-variable={props.data.variable}
            data-date={props.data.date}
            data-center={props.data.center}
            data-level={props.type[0].id}
            data-y={props.type[0].id}
          >
            {props.type[0].name}
          </a>
          <a
            id="link-chart"
            onClick={fetchStationData}
            href="#"
            data-station={props.data.station}
            data-variable={props.data.variable}
            data-date={props.data.date}
            data-center={props.data.center}
            data-level={props.type[1].id}
            data-y={props.type[1].id}
          >
            {props.type[1].name}
          </a>
        </div>
      </div>
    );
  }
  return component;
};

export default LinkTimeSeries;
