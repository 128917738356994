import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { sortBy, reverse } from "lodash";
import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";

import { Spin } from "antd";
import DBHeader from "./DBHeader/DBHeader";
import DBBody from "./DBBody/DBBody";
import DBFooter from "./DBFooter/DBFooter";
import DBOscarInfo from "./DBOscarInfo/DBOscarInfo";

import {
  getListMonths,
  getListYears,
  processNWPData,
  processGCOSData
} from "./utils";

import "./DatabaseStatus.css";

const DatabaseStatus = props => {
  const module = props.params.module;
  let prefix = "";
  if (props.params.module === "gbon") {
    prefix = "gbon_";
  }
  // the default file types are 'synop'
  // for module 'nwp' and 'gsn' for module 'gcos'
  let [currentFileType, setCurrentFileType] = useState(
    module === "nwp" || module === "gbon" ? "synop" : "gsn"
  );
  let [tabs, setTabs] = useState({
    nwp: {
      hasHeader: true,
      active: "surface",
      networks: {
        surface: {
          name: "Surface",
          fileType: "synop"
        },
        upperAir: {
          name: "Upper air",
          fileType: "temp"
        }
      }
    },
    marine: {
      hasHeader: true,
      active: "buoy",
      networks: {
        buoy: {
          name: "Buoy",
          fileType: "buoy"
        },
        ship: {
          name: "Ship",
          fileType: "ship"
        }
      }
    },
    gbon: {
      hasHeader: true,
      active: "surface",
      networks: {
        surface: {
          name: "Surface",
          fileType: "synop"
        },
        upperAir: {
          name: "Upper air",
          fileType: "temp"
        }
      }
    },
    gcos: {
      hasHeader: false,
      active: "surface",
      networks: {
        surface: {
          name: "Surface",
          fileType: "gsn"
        },
        upperAir: {
          name: "Upper air",
          fileType: "guan"
        }
      }
    }
  });
  // The index of the month currently displayed
  // for each module/network
  let [currentIndexes, setCurrentIndexes] = useState({
    nwp: {
      synop: 0,
      temp: 0
    },
    gbon: {
      synop: 0,
      temp: 0
    },
    marine: {
      buoy: 0,
      ship: 0
    },
    gcos: {
      gsn: 0,
      guan: 0
    }
  });
  // Contains an array of all the available months
  // per module/network
  let [possiblePeriods, setPossiblePeriods] = useState({
    nwp: {
      synop: null,
      temp: null
    },
    gbon: {
      synop: null,
      temp: null
    },
    marine: {
      buoy: null,
      ship: null
    },
    gcos: {
      gsn: null,
      guan: null
    }
  });
  let [body, setBody] = useState(
    <Spin className="spinner-periods" tip="Loading..." />
  );
  let [footer, setFooter] = useState(null);
  let [oscar_status, setOscarStatus] = useState(null);

  // When switching to the DB status page of another module
  useEffect(() => {
    setCurrentFileType(tabs[module].networks[tabs[module].active].fileType);

    // Google Analytics
    if (window.location.hostname !== "localhost") {
      // Update the user's current page
      ReactGA.set({
        page: window.location.pathname
      });
      // Record a pageview for the given page
      ReactGA.pageview(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  // when changing module/tab
  useEffect(() => {
    // reset
    setBody(<Spin className="spinner-periods" tip="Loading..." />);
    setFooter(null);
    setOscarStatus(null);

    // check if the data was already fetched
    const networkCode = tabs[module].networks[tabs[module].active].fileType;
    let isAlreadyFetched = props[networkCode].dataFetched;
    if (module === "gbon") {
      isAlreadyFetched = props["gbon_" + networkCode].dataFetched;
    }
    if (!isAlreadyFetched) {
      if (module === "nwp") {
        props.onFetchDataFromApi({
          fileType: networkCode,
          alreadyFetched: isAlreadyFetched,
          params: {}
        });
      } else if (module === "marine") {
        props.onFetchMarineDataFromApi({
          fileType: networkCode,
          alreadyFetched: isAlreadyFetched,
          params: {}
        });
      } else if (module === "gbon") {
        props.onFetchGbonDataFromApi({
          baseline: "GBON",
          fileType: "gbon_" + networkCode,
          parentFileType: networkCode, // either synop or temp
          alreadyFetched: isAlreadyFetched,
          params: {}
        });
      } else {
        props.onFetchGCOSDataFromApi({
          fileType: networkCode,
          alreadyFetched: isAlreadyFetched,
          params: {}
        });
      }
    }
  }, [module, tabs]);

  // Done once per file type once the data has been fetched
  useEffect(() => {
    // If the data has been fetched

    if (props[prefix + currentFileType]?.dataFetched) {
      const newState = { ...possiblePeriods };
      // If there is data for that module
      if (!props[prefix + currentFileType].noData) {
        let periods;
        if (module === "nwp" || module === "gbon" || module === "marine") {
          const originalData =
            props[prefix + currentFileType].periodData.six_hour.dates;

          if (originalData === null) {
            newState[module][currentFileType] = [];
            setPossiblePeriods(newState);
          } else {
            // Get all the dates from property 'all' in an array
            const allDates = Object.keys(originalData.all);

            // IMPORTANT: order dates from newest to oldest
            const allDatesSorted = reverse(sortBy(allDates));

            // For each month available get its last day
            periods = getListMonths(
              allDatesSorted[0],
              allDatesSorted[allDatesSorted.length - 1]
            );
          }
        } else {
          // GCOS
          // Get the last date of each year
          const allDates = Object.keys(props[currentFileType].dates);
          periods = getListYears(allDates[0], allDates[allDates.length - 1]);
        }
        newState[module][currentFileType] = periods;
        setPossiblePeriods(newState);
      } else {
        // If there is no data for that module
        // set `possiblePeriods` for that module
        // to an empty array
        newState[module][currentFileType] = [];
        setPossiblePeriods(newState);
      }
    }
  }, [
    tabs,
    props[prefix + currentFileType]?.dataFetched,
    props[prefix + currentFileType]?.allDataFetched
  ]);

  // Done when clicking on previous or next month
  useEffect(() => {
    const periods = possiblePeriods[module][currentFileType];
    if (periods && periods.length > 0) {
      let allDates;
      let templateArray;
      if (module === "nwp" || module === "gbon" || module === "marine") {
        allDates = props[prefix + currentFileType].periodData.six_hour.dates;
        templateArray = processNWPData(
          allDates,
          periods[currentIndexes[module][currentFileType]]
        );
        console.log(templateArray);
      } else {
        // GCOS
        allDates = Object.keys(props[currentFileType].dates);
        templateArray = processGCOSData(
          allDates,
          periods[currentIndexes[module][currentFileType]]
        );
      }

      setBody(
        <DBBody
          module={module}
          template={templateArray}
          fileType={currentFileType}
        />
      );
      setFooter(
        <DBFooter
          metadata={{
            module,
            currentFileType,
            currentIndexes
          }}
          periods={periods}
          index={currentIndexes[module][currentFileType]}
          onUpdateIndex={setCurrentIndexes}
        />
      );
      setOscarStatus(<DBOscarInfo />);
    } else if (periods && periods.length === 0) {
      // if `periods` is an empty array, it means that there is
      // no data for that module
      setBody(<div className="dbs-nodata">No data available</div>);
    }
  }, [
    prefix + currentFileType,
    currentIndexes,
    possiblePeriods[module][currentFileType]
  ]);

  const activateTab = tab => {
    // tab is an array. Index 0 is the key of one
    // of the 'tabs' we clicked on
    // Index 1 is its values

    // We only update the state if the tab is not active
    if (!tab[1].active) {
      const newState = { ...tabs };
      newState[module].active = tab[0];
      setTabs(newState);
      setCurrentFileType(newState[module].networks[tab[0]].fileType);
    }
  };

  const Tabs = Object.entries(tabs[module].networks).map(t => {
    const isActive = t[0] === tabs[module].active ? "active" : "inactive";
    return (
      <button
        key={t[0]}
        onClick={() => activateTab(t)}
        className={`tablink tablink-${isActive}`}
      >
        {t[1].name}
      </button>
    );
  });

  const header = tabs[module].hasHeader ? <DBHeader /> : null;

  return (
    <div className="container-dbs">
      <div className="dbs-tabs">{Tabs}</div>
      <div className="dbs-header">{header}</div>
      <div className="dbs-body">{body}</div>
      <div className="dbs-footer">{footer}</div>
      <div className="oscar-status">{oscar_status}</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    gsn: state.gsn,
    guan: state.guan,
    buoy: state.buoy,
    ship: state.ship
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFetchDataFromApi: payload => {
      dispatch(actions.fetchDataFromApi(payload, "DB_STATUS"));
    },
    onFetchMarineDataFromApi: payload => {
      dispatch(actions.fetchMarineDataFromApi(payload, "DB_STATUS"));
    },
    onFetchGbonDataFromApi: payload => {
      dispatch(actions.fetchGbonDataFromApi(payload, "DB_STATUS"));
    },
    onFetchGCOSDataFromApi: payload => {
      dispatch(actions.fetchGCOSDataFromApi(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseStatus);
