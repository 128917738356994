import React from "react";

import "./Footer.css";
import { WDQMS_VERSION } from "../../utils/general";
const Footer = props => {
  return (
    <footer className="wdqms-footer">
      <span>&copy; WMO</span>
      <span>
        Materials presented on this website, particularly maps and territorial
        information, do not imply the expression of any opinion whatsoever on
        the part of the Secretariat of the World Meteorological Organization
        concerning the legal status of any country, territory, city or area or
        of its authorities, or concerning the delimitation of its frontiers or
        boundaries.
      </span>
      <span>
        Version:{" "}
        <a
          href="https://confluence.ecmwf.int/display/WIGOSWT/Releases"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* {process.env.REACT_APP_VERSION} */}
          {WDQMS_VERSION}
        </a>
      </span>
    </footer>
  );
};

export default Footer;
