import ReactGA from "react-ga";
import { timeFormat, timeParse } from "d3-time-format";
import moment from "moment";

const protocol =
  window.location.hostname === "localhost"
    ? "https:"
    : window.location.protocol;

export const WDQMS_VECTOR_TILES = `${protocol}//${window.location.hostname}/wdqmsapi/vector-tiles`;
export const WDQMS_TILES = `${protocol}//${window.location.hostname}/wdqmstiles`;

// Get the version from the package.json file
var pjson = require("../../package.json");
export const WDQMS_VERSION = pjson.version;

// This is the date when we switched to the new NCEP SYNOP files
// where the hummidity is correct and can be displayed.
// Before this date we 'hide' the humidity from NCEP/Quality.
// This same date is also declared in Django:
// views/constants.py
export const dateNewNCEPSYNOP = "2020-07-06";

export const periodDataTemplate = {
  six_hour: {
    centers: null,
    dates: null,
    errorFetchingApi: false,
    noData: false,
    baseline: null
  },
  daily: {
    centers: null,
    dates: null,
    errorFetchingApi: false,
    noData: false,
    baseline: null
  },
  alert: {
    centers: null,
    dates: null,
    errorFetchingApi: false,
    noData: false,
    baseline: null
  },
  monthly: {
    centers: null,
    dates: null,
    errorFetchingApi: false,
    noData: false,
    baseline: null
  }
};

export const allCenters = ["DWD", "ECMWF", "JMA", "NCEP"];

export const reports = {
  synop: {
    availability: [
      {
        code: "110",
        name: "Surface pressure",
        unit: "hPa",
        shortName: "Pressure"
      },
      {
        code: "39",
        name: "2m temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "41",
        name: "10m zonal wind",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "42",
        name: "10m meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "58",
        name: "2m relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ],
    quality: [
      {
        code: "110",
        name: "Surface pressure",
        unit: "hPa",
        shortName: "Pressure"
      },
      {
        code: "1",
        name: "Geopotential",
        unit: "m",
        shortName: "Geopotential"
      },
      {
        code: "39",
        name: "2m temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "41",
        name: "10m zonal wind ",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "42",
        name: "10m meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "58",
        name: "2m relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ]
  },
  gbon_synop: {
    availability: [
      {
        code: "110",
        name: "Surface pressure",
        unit: "hPa",
        shortName: "Pressure"
      },
      {
        code: "39",
        name: "2m temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "41",
        name: "10m zonal wind",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "42",
        name: "10m meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "58",
        name: "2m relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ],
    quality: [
      {
        code: "110",
        name: "Surface pressure",
        unit: "hPa",
        shortName: "Pressure"
      },
      {
        code: "1",
        name: "Geopotential",
        unit: "m",
        shortName: "Geopotential"
      },
      {
        code: "39",
        name: "2m temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "41",
        name: "10m zonal wind",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "42",
        name: "10m meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "58",
        name: "2m relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ]
  },
  temp: {
    // variables are not used for TEMP/Availability
    // but we need to have a object to set`selectedVariable`
    // and construct the URL for the vector-tiles
    availability: [
      {
        code: "2",
        name: "Temperature",
        unit: "K",
        shortName: "Temperature"
      }
    ],
    quality: [
      {
        code: "2",
        name: "Temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "3",
        name: "Zonal wind",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "4",
        name: "Meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "29",
        name: "Relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ]
  },
  gbon_temp: {
    // variables are not used for TEMP/Availability
    // but we need to have a object to set`selectedVariable`
    // and construct the URL for the vector-tiles
    availability: [
      {
        code: "2",
        name: "Temperature",
        unit: "K",
        shortName: "Temperature"
      }
    ],
    quality: [
      {
        code: "2",
        name: "Temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "3",
        name: "Zonal wind",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "4",
        name: "Meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "29",
        name: "Relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ]
  },
  guan: {
    availability: { byVariable: false },
    quality: {
      byVariable: true,
      variables: [
        { code: "temperature", name: "Temperature" },
        { code: "wind", name: "Wind" },
        { code: "humidity", name: "Humidity" }
      ]
    }
  },
  gsn: {
    availability: { byVariable: false },
    completeness: {
      byVariable: true,
      variables: [
        { code: "pressure", name: "pressure" },
        { code: "temperature", name: "temperature" },
        { code: "max_temperature", name: "max temperature" },
        { code: "min_temperature", name: "min temperature" },
        { code: "watervapor", name: "water vapor" },
        { code: "precipitation", name: "precipitation" },
        { code: "sunshine_duration", name: "sunshine duration" }
      ]
    }
  },
  buoy: {
    quality: [
      {
        code: "110",
        name: "Surface pressure",
        unit: "hPa",
        shortName: "Pressure"
      },
      {
        code: "39",
        name: "2m temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "41",
        name: "10m zonal wind ",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "42",
        name: "10m meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "58",
        name: "2m relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ]
  },
  ship: {
    quality: [
      {
        code: "110",
        name: "Surface pressure",
        unit: "hPa",
        shortName: "Pressure"
      },
      {
        code: "39",
        name: "2m temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "41",
        name: "10m zonal wind ",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "42",
        name: "10m meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "58",
        name: "2m relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ]
  },
  marine_surface: {
    quality: [
      {
        code: "110",
        name: "Surface pressure",
        unit: "hPa",
        shortName: "Pressure"
      },
      {
        code: "39",
        name: "2m temperature",
        unit: "K",
        shortName: "Temperature"
      },
      {
        code: "41",
        name: "10m zonal wind ",
        unit: "m/s",
        shortName: "Zonal wind"
      },
      {
        code: "42",
        name: "10m meridional wind",
        unit: "m/s",
        shortName: "Meridional wind"
      },
      {
        code: "58",
        name: "2m relative humidity",
        unit: "%",
        shortName: "Humidity"
      }
    ]
  }
};

// Used to match the variable in the URL to its ID
export const variablesSlug = {
  synop: {
    pressure: "110",
    geopotential: "1",
    temperature: "39",
    zonal_wind: "41",
    meridional_wind: "42",
    humidity: "58"
  },
  temp: {
    temperature: "2",
    zonal_wind: "3",
    meridional_wind: "4",
    humidity: "29"
  },
  gbon_synop: {
    pressure: "110",
    geopotential: "1",
    temperature: "39",
    zonal_wind: "41",
    meridional_wind: "42",
    humidity: "58"
  },
  gbon_temp: {
    temperature: "2",
    zonal_wind: "3",
    meridional_wind: "4",
    humidity: "29"
  },
  buoy: {
    pressure: "110",
    temperature: "39",
    zonal_wind: "41",
    meridional_wind: "42",
    humidity: "58"
  },
  ship: {
    pressure: "110",
    temperature: "39",
    zonal_wind: "41",
    meridional_wind: "42",
    humidity: "58"
  },
  marine_surface: {
    pressure: "110",
    temperature: "39",
    zonal_wind: "41",
    meridional_wind: "42",
    humidity: "58"
  }
};

// Given a fileType ("synop" or "temp") and a varid
// return its corresponding variable name in "variablesSlug"
export const reverseMapVariablesSlug = (fileType, varid) => {
  for (const property in variablesSlug[fileType]) {
    if (variablesSlug[fileType][property] === varid) {
      return property;
    }
  }
};

export const TempVariablesShortName = {
  2: "Temperature",
  3: "Zonal wind",
  4: "Meridional wind",
  29: "Humidity"
};

export const TempCompletenessStatus = {
  4: "Partial (missing layer).",
  3: "Full",
  2: "Partial (missing variable)",
  1: "Partial (missing layer)",
  0: "Empty"
};

export const getPeriodsAsQueryParameters = (
  periodData,
  periodType,
  center,
  date,
  sixHPeriod
) => {
  const day = periodData[periodType].dates[center][date];
  let strPeriods = "";
  if (periodType === "six_hour") {
    if (center === "all") {
      for (let p of Object.keys(day[sixHPeriod].id)) {
        strPeriods += `period_${p}=${day[sixHPeriod].id[p]}&`;
      }
      // remove the `&` at the end fo the string
      strPeriods = strPeriods.substring(0, strPeriods.length - 1);
    } else {
      strPeriods = `period_${center}=${day[sixHPeriod].id}`;
    }
  } else if (
    periodType === "daily" ||
    periodType === "alert" ||
    periodType === "monthly"
  ) {
    if (center === "all") {
      for (let p of Object.keys(day)) {
        strPeriods += `period_${p}=${day[p]}&`;
      }
      // remove the `&` at the end fo the string
      strPeriods = strPeriods.substring(0, strPeriods.length - 1);
    } else {
      strPeriods = `period_${center}=${day.id}`;
    }
  }
  return strPeriods;
};

export const getMarinePeriodsAsQueryParameters = (
  file_type,
  periodData,
  periodType,
  center,
  date,
  sixHPeriod
) => {
  const day = periodData[periodType].dates[center][file_type][date];
  let strPeriods = "";
  try {
    if (periodType === "six_hour") {
      if (center === "all") {
        for (let p of Object.keys(day[sixHPeriod].id)) {
          strPeriods += `period_${p}=${day[sixHPeriod].id[p]}&`;
        }
        // remove the `&` at the end fo the string
        strPeriods = strPeriods.substring(0, strPeriods.length - 1);
      } else {
        strPeriods = `period_${center}=${day[sixHPeriod].id}`;
      }
    } else if (
      periodType === "daily" ||
      periodType === "alert" ||
      periodType === "monthly"
    ) {
      if (center === "all") {
        for (let p of Object.keys(day)) {
          strPeriods += `period_${p}=${day[p]}&`;
        }
        // remove the `&` at the end fo the string
        strPeriods = strPeriods.substring(0, strPeriods.length - 1);
      } else {
        strPeriods = `period_${center}=${day.id}`;
      }
    }
  } catch (error) {
    console.log(error);
  }
  return strPeriods;
};

export const getPeriodId = (
  periodData,
  periodType,
  center,
  date,
  sixHPeriod
) => {
  const day = periodData[periodType].dates[center][date];
  let strPeriods = "";
  if (periodType === "six_hour") {
    if (center === "all") {
      // Sort is important so that get the same result as
      // in the Python vector-tiles service
      for (let p of Object.keys(day[sixHPeriod].id).sort()) {
        strPeriods +=
          day[sixHPeriod].id[p] !== null
            ? day[sixHPeriod].id[p].toString()
            : "";
      }
    } else {
      strPeriods = day[sixHPeriod].id.toString();
    }
  } else if (
    periodType === "daily" ||
    periodType === "alert" ||
    periodType === "monthly"
  ) {
    if (center === "all") {
      for (let p of Object.keys(day).sort()) {
        strPeriods += day[p].toString();
      }
    } else {
      strPeriods = day.id.toString();
    }
  }
  return strPeriods;
};

/**
 * This function return max of nr recieved and nr_expected in case of geopotenial variables exist
 * when there is duplicate rows for the same center in the same month, it means, there is
 * new more value for surface or gepotential variable
 * @returns {Array} newArr conatains the max of nr recieved and nr_expected
 * @param {Array} returnedData - data returned from API to be parsed
 */
export const maxNrRecievedNrExpected = returnedData => {
  let newArr = [];
  if (Array.isArray(returnedData)) {
    returnedData.forEach(item => {
      let hasDateAndCenter = newArr.findIndex(
        obj => obj.center === item.center && obj.date === item.date
      );
      if (hasDateAndCenter !== -1) {
        newArr[hasDateAndCenter].nr_received = Math.max(
          newArr[hasDateAndCenter].nr_received,
          item.nr_received
        );
        newArr[hasDateAndCenter].nr_expected = Math.max(
          newArr[hasDateAndCenter].nr_expected,
          item.nr_expected
        );
      } else {
        newArr.push(item);
      }
    });
  }
  return newArr;
};
// this function is used to keep only surface pressure or geopotential
// when both are present
export const filterArray = (arr, keys, criteria, advantage) => {
  // arr: the array to filter. arr is sorted so that 2 elements
  // to compare are always adjacent
  // keys: an array of keys contained in the elements of the array `arr`
  // Those are the keys used to know if we can compare 2 elements
  // criteria: a string. The key of the element used to know which
  // element to keep. The comparaison is '>' (greater than)
  // advantage: If the 2 elements have the same value for the
  // key corrresponding to the criteria, need to determine which one to keep
  // `advantage` is an object whose only key correspond to the key of the elements of
  // `arr` used to know which one is more important than the other. The value
  // is teh value that has more importance.

  // if there is one or no element, nothing to filter
  if (arr.length <= 1) {
    return arr;
  }

  const newArr = [];
  let lastObj = {};
  for (let i = 1; i < arr.length; i++) {
    const prev = arr[i - 1];
    const element = arr[i];
    const comparable = compareKeys(prev, element, keys);
    if (comparable) {
      if (
        prev[criteria] > element[criteria] &&
        !compareKeys(lastObj, prev, keys)
      ) {
        newArr.push(prev);
        lastObj = extractKeysFromObj(prev, keys);
      } else if (
        prev[criteria] < element[criteria] &&
        !compareKeys(lastObj, element, keys)
      ) {
        newArr.push(element);
        lastObj = extractKeysFromObj(element, keys);
      } else if (
        prev[Object.keys(advantage)[0]] === Object.values(advantage)[0]
      ) {
        if (!compareKeys(lastObj, prev, keys)) {
          newArr.push(prev);
          lastObj = extractKeysFromObj(prev, keys);
        }
      } else if (!compareKeys(lastObj, element, keys)) {
        newArr.push(element);
        lastObj = extractKeysFromObj(element, keys);
      }
    } else {
      if (!compareKeys(lastObj, prev, keys)) {
        newArr.push(prev);
        lastObj = extractKeysFromObj(prev, keys);
      }
      if (i === arr.length - 1) {
        newArr.push(element);
      }
    }
  }
  return newArr;
};

// exported only for tests
export const compareKeys = (element1, element2, keys) => {
  // Order of element1 and element2 is important if they don't have the same (number of) keys
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (element1[key] !== element2[key]) {
      return false;
    }
  }
  return true;
};

// exported only for tests
export const extractKeysFromObj = (obj, keys) => {
  let newObj = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    newObj[key] = obj[key];
  }
  return newObj;
};

const getDataType = fileType => {
  let dataType;
  if (fileType === "synop" || fileType === "gsn") {
    dataType = "land_surface";
  } else if (fileType === "temp" || fileType === "guan") {
    dataType = "land_upper-air";
  } else if (
    fileType === "buoy" ||
    fileType === "ship" ||
    fileType === "marine_surface"
  ) {
    dataType = fileType;
  }
  return dataType;
};

// This function is used in the 'MapMenu' component
// inside 'mapDispatchToProps'
// where 'props' is 'ownProps'
// 'state' is 'getState()[ownProps.fileType]'
export const updateURL = (props, state, fileType, partToUpdate, newPart) => {
  let indexToUpdate;
  let pathParts;

  let varName = newPart;

  const dataType = getDataType(fileType);

  if (
    (props.fileType === "synop" || props.fileType === "temp") &&
    props.baseline === "OSCAR"
  ) {
    // for TEMP/Availability, the variable is not part of the URL
    if (fileType === "temp" && state.selectedReport === "availability") {
      if (partToUpdate === "periodType") {
        indexToUpdate = 3;
      } else if (partToUpdate === "report") {
        indexToUpdate = 4;
      } else if (partToUpdate === "center") {
        indexToUpdate = 5;
      } else if (partToUpdate === "date") {
        indexToUpdate = 6;
      } else if (partToUpdate === "sixh-period") {
        indexToUpdate = 7;
      }

      pathParts = [
        "",
        "nwp",
        dataType,
        state.selectedPeriodType,
        state.selectedReport,
        state.selectedCenter,
        state.selectedDate
      ];
    } else {
      const variableSlug = reverseMapVariablesSlug(
        fileType,
        state.selectedVariable
      );

      pathParts = [
        "",
        "nwp",
        dataType,
        state.selectedPeriodType,
        state.selectedReport,
        variableSlug,
        state.selectedCenter,
        state.selectedDate
      ];

      if (partToUpdate === "periodType") {
        indexToUpdate = 3;
      } else if (partToUpdate === "report") {
        indexToUpdate = 4;
      } else if (partToUpdate === "variable") {
        indexToUpdate = 5;
      } else if (partToUpdate === "center") {
        indexToUpdate = 6;
      } else if (partToUpdate === "date") {
        indexToUpdate = 7;
      } else if (partToUpdate === "sixh-period") {
        indexToUpdate = 8;
      }

      // if have to update the variable name, get its name from its ID
      if (indexToUpdate === 5) {
        varName = reverseMapVariablesSlug(fileType, newPart);
      }
    }

    if (state.selectedPeriodType === "six_hour") {
      pathParts.push(state.selectedSixHPeriod);
    }
  } else if (
    (props.fileType === "synop" || props.fileType === "temp") &&
    props.baseline === "GBON"
  ) {
    let prefix = "gbon_";
    // for TEMP/Availability, the variable is not part of the URL
    if (fileType === "temp" && state.selectedReport === "availability") {
      if (partToUpdate === "periodType") {
        indexToUpdate = 3;
      } else if (partToUpdate === "report") {
        indexToUpdate = 4;
      } else if (partToUpdate === "center") {
        indexToUpdate = 5;
      } else if (partToUpdate === "date") {
        indexToUpdate = 6;
      } else if (partToUpdate === "sixh-period") {
        indexToUpdate = 7;
      }

      pathParts = [
        "",
        "gbon",
        dataType,
        state.selectedPeriodType,
        state.selectedReport,
        state.selectedCenter,
        state.selectedDate
      ];
    } else {
      const variableSlug = reverseMapVariablesSlug(
        prefix + fileType,
        state.selectedVariable
      );

      pathParts = [
        "",
        "gbon",
        dataType,
        state.selectedPeriodType,
        state.selectedReport,
        variableSlug,
        state.selectedCenter,
        state.selectedDate
      ];

      if (partToUpdate === "periodType") {
        indexToUpdate = 3;
      } else if (partToUpdate === "report") {
        indexToUpdate = 4;
      } else if (partToUpdate === "variable") {
        indexToUpdate = 5;
      } else if (partToUpdate === "center") {
        indexToUpdate = 6;
      } else if (partToUpdate === "date") {
        indexToUpdate = 7;
      } else if (partToUpdate === "sixh-period") {
        indexToUpdate = 8;
      }

      // if have to update the variable name, get its name from its ID
      if (indexToUpdate === 5) {
        varName = reverseMapVariablesSlug(prefix + fileType, newPart);
      }
    }

    if (state.selectedPeriodType === "six_hour") {
      pathParts.push(state.selectedSixHPeriod);
    }
  } else if (
    props.fileType === "buoy" ||
    props.fileType === "ship" ||
    fileType === "marine_surface"
  ) {
    let prefix = "";
    // for TEMP/Availability, the variable is not part of the URL
    if (state.selectedReport === "availability") {
      if (partToUpdate === "periodType") {
        indexToUpdate = 3;
      } else if (partToUpdate === "report") {
        indexToUpdate = 4;
      } else if (partToUpdate === "center") {
        indexToUpdate = 5;
      } else if (partToUpdate === "date") {
        indexToUpdate = 6;
      } else if (partToUpdate === "sixh-period") {
        indexToUpdate = 7;
      }

      pathParts = [
        "",
        "nwp",
        dataType,
        state.selectedPeriodType,
        state.selectedReport,
        state.selectedCenter,
        state.selectedDate
      ];
    } else {
      const variableSlug = reverseMapVariablesSlug(
        prefix + fileType,
        state.selectedVariable
      );

      pathParts = [
        "",
        "nwp",
        dataType,
        state.selectedPeriodType,
        state.selectedReport,
        variableSlug,
        state.selectedCenter,
        state.selectedDate
      ];

      if (partToUpdate === "periodType") {
        indexToUpdate = 3;
      } else if (partToUpdate === "report") {
        indexToUpdate = 4;
      } else if (partToUpdate === "variable") {
        indexToUpdate = 5;
      } else if (partToUpdate === "center") {
        indexToUpdate = 6;
      } else if (partToUpdate === "date") {
        indexToUpdate = 7;
      } else if (partToUpdate === "sixh-period") {
        indexToUpdate = 8;
      }

      // if have to update the variable name, get its name from its ID
      if (indexToUpdate === 5) {
        varName = reverseMapVariablesSlug(prefix + fileType, newPart);
      }
    }

    if (state.selectedPeriodType === "six_hour") {
      pathParts.push(state.selectedSixHPeriod);
    }
  } else if (props.fileType === "guan") {
    if (state.selectedReport === "availability") {
      pathParts = [
        "",
        "gcos",
        dataType,
        state.selectedReport,
        state.selectedDate
      ];
    } else {
      // Quality
      pathParts = [
        "",
        "gcos",
        dataType,
        state.selectedReport,
        state.selectedVariable,
        state.selectedDate
      ];
    }
  } else if (props.fileType === "gsn") {
    if (state.selectedReport === "availability") {
      pathParts = [
        "",
        "gcos",
        dataType,
        state.selectedReport,
        state.selectedDate
      ];
    } else {
      // Completeness
      pathParts = [
        "",
        "gcos",
        dataType,
        state.selectedReport,
        state.selectedVariable,
        state.selectedDate
      ];
    }
  }

  pathParts[indexToUpdate] = varName;
  const newURL = pathParts.join("/");
  props.history.replace(newURL);
  // Google analytics
  if (window.location.hostname !== "localhost") {
    // Update the user's current page
    ReactGA.set({ page: newURL });
    // Record a pageview for the given page
    ReactGA.pageview(newURL);
  }
};

export const get6hPeriod = date => {
  // Given a datetime, returns the six hour period it belongs to

  const dateF = "%Y-%m-%dT%H:%M:%SZ";

  const parserTime = timeParse(dateF);
  const formaterString = timeFormat(dateF);

  const d = parserTime(date);

  let py = d.getFullYear();
  let pm = d.getMonth();
  let pd = d.getDate();
  let dh = d.getHours();

  let ph;

  if (dh < 3 || dh >= 21) {
    ph = 0;
    if (dh >= 21) {
      d.setDate(d.getDate() + 1);

      py = d.getFullYear();
      pm = d.getMonth();
      pd = d.getDate();
    }
  } else if (dh < 9) {
    ph = 6;
  } else if (dh < 15) {
    ph = 12;
  } else if (dh < 21) {
    ph = 18;
  }

  return formaterString(new Date(py, pm, pd, ph, 0, 0, 0));
};

export const get6hPeriodBoundary = date => {
  // returns the upper doundary of a six-hour period
  const format = "YYYY-MM-DD HH:mm:ss";
  const newD = moment(date)
    .add(2, "hours")
    .add(59, "minutes")
    .add(59, "seconds")
    .format(format);

  return newD;
};

// Given a month (string in format YYYY-MM)
// returns the number of days in this month
export const getDaysInMonth = month => moment(month).daysInMonth();
