import moment from "moment";
import { prepareNwpDataForChart } from "./nwp";
import { prepareGcosDataForChart } from "./gcos";

export const prepareDataForChart = (fileType, props) => {
  let ret;
  if (
    fileType === "synop" ||
    fileType === "temp" ||
    fileType === "ship" ||
    fileType === "buoy" ||
    fileType === "marine_surface"
  ) {
    ret = prepareNwpDataForChart(fileType, props);
  } else if (fileType === "gsn" || fileType === "guan") {
    ret = prepareGcosDataForChart(fileType, props);
  }
  return ret;
};

// `formatPeriodSeries` is used to format the observations
// aggregated by periods (opposed to the individual observations)
// The returned object is of the following structures
// For availability:
// {
// dwd: { data: [{ x: "2019-07-17T06:00:00Z": y: 2 }, { x: "2019-07-17T12:00:00Z", y: null }, { x: "2019-07-17T18:00:00Z", y: 2 }],
// max: 2, min: 0, representAs: 'lines' }
// ecmwf: { data: Array,  max: 6, min: 0, representAs: 'lines' }
// expected: { data: [{ x: "2019-07-17T06:00:00Z", y: 6 }, { x: "2019-07-17T12:00:00Z", y: null }, { x: "2019-07-17T18:00:00Z", y: 6 }],
// max: 6, min: 0, representAs: 'lines' }
// jma: { data: Array, max: 6, min: 0, representAs: 'lines' }
// ncep: { data: Array, max: 6, min: 0, representAs: 'lines' }
// }

// For Quality, six-hour, simple (here for ECMWF):
// {
// ecmwf:
// data: [{x: "2019-07-09T18:00:00Z", y: null}, {x: "2019-07-10T00:00:00Z", y: 0.211500002692143}, {x: "2019-07-10T06:00:00Z", y: 0.479666670163472},
// {x: "2019-07-10T12:00:00Z", y: 0.168499998748303}],
// max: 0.560166656970978,
// min: -0.158333333170352,
// representAs: 'points'
// }
export const formatPeriodSeries = (
  data,
  seriesNames,
  allTimeSteps,
  allTimeStepsNull,
  keyData,
  keyExpected,
  props
) => {
  // initialize an object with one key per center
  // plus one for the expected values
  // For each one set its value as a copy of `allTimeStepsNull`
  let series = {};
  seriesNames.forEach(function(serie) {
    series[serie.name] = {
      data: allTimeStepsNull.map(a => ({ ...a })),
      max: -Infinity,
      min: Infinity,
      label: serie.label,
      color: serie.color,
      representAs: serie.representAs,
      tooltip: serie.tooltip
    };
  });
  console.log(keyData);
  // Edit the values based on the keys `keyData`
  // and `keyExpected` (if it's not null)
  data.forEach(function(d) {
    const center = d.center.toLowerCase();
    const indexToUpdate = allTimeSteps.indexOf(d.date);
    series[center].data[indexToUpdate] = {
      x: d.date,
      y: d[keyData]
    };

    if (d[keyData] > series[center].max) {
      series[center].max = d[keyData];
    }
    if (d[keyData] && d[keyData] < series[center].min) {
      series[center].min = d[keyData];
    }
    if (keyExpected) {
      // if (props.selectedPeriodType === "monthly" && props.combinedMode) {
      //   series[keyExpected].data[indexToUpdate] = {
      //     x: d.date,
      //     y: d[keyExpected]
      //   };
      // } else {
      //   series[keyExpected].data[indexToUpdate] = {
      //     x: d.date,
      //     y: d[keyExpected]
      //   };
      // }
      series[keyExpected].data[indexToUpdate] = {
        x: d.date,
        y: d[keyExpected]
      };
      if (d[keyExpected] > series[keyExpected].max) {
        series[keyExpected].max = d[keyExpected];
      }
      if (d[keyExpected] && d[keyExpected] < series[keyExpected].min) {
        series[keyExpected].min = d[keyExpected];
      }
    }
  });
  return series;
};

// Utility functions

// rangeBetweenDates returns an array of all the periods (six-hour or daily)
// that are between `dateStart` and a number of days (`nbrDays`)

// exported only for test
export const rangeBetweenDates = (
  dateStart,
  nbrDays,
  selectedSixHPeriod,
  sixHourPeriods = []
) => {
  const s = moment.utc(dateStart);
  let arr = [];
  // Add entries for dateStart
  if (sixHourPeriods.length > 0) {
    const currentIndex = sixHourPeriods.indexOf(selectedSixHPeriod);
    const subSixHourPeriods = sixHourPeriods.slice(currentIndex);

    for (let j = 0; j < subSixHourPeriods.length; j++) {
      arr.push(
        s.utc().format("YYYY-MM-DD") + "T" + subSixHourPeriods[j] + ":00:00Z"
      );
    }
  } else {
    arr.push(s.utc().format("YYYY-MM-DD") + "T00:00:00Z");
  }
  // Add entries for older days
  for (let i = 1; i <= nbrDays - 1; i++) {
    // always substract 1 because substract mutates the original moment by subtracting time.
    const t = s.subtract(1, "days");
    if (sixHourPeriods.length > 0) {
      for (let j = 0; j < sixHourPeriods.length; j++) {
        arr.push(
          t.utc().format("YYYY-MM-DD") + "T" + sixHourPeriods[j] + ":00:00Z"
        );
      }
    } else {
      arr.push(t.utc().format("YYYY-MM-DD") + "T00:00:00Z");
    }
  }
  // For the last day - in case of six-hour periods - we take the periods
  // up to selectedSixHPeriod
  const t = s.subtract(1, "days");
  if (sixHourPeriods.length > 0) {
    const currentIndex = sixHourPeriods.indexOf(selectedSixHPeriod);
    const subSixHourPeriods = sixHourPeriods.slice(0, currentIndex + 1);
    for (let j = 0; j < subSixHourPeriods.length; j++) {
      arr.push(
        t.utc().format("YYYY-MM-DD") + "T" + subSixHourPeriods[j] + ":00:00Z"
      );
    }
  } else {
    arr.push(t.utc().format("YYYY-MM-DD") + "T00:00:00Z");
  }
  return arr.reverse();
};

// Similar to 'rangeBetweenDates' above but with months
export const rangeMonthsBetweenDates = (dateStart, nbrMonths) => {
  const s = moment.utc(dateStart);
  let arr = [];

  arr.push(s.utc().format("YYYY-MM-DD") + "T00:00:00Z");

  for (let i = 1; i <= nbrMonths; i++) {
    const t = s.subtract(1, "months");
    arr.push(t.utc().format("YYYY-MM-DD") + "T00:00:00Z");
  }

  return arr.reverse();
};

export const updateTimeSteps = (series, allTimeSteps) => {
  // Update allTimeSteps to include the individual dates from all series
  // This is used when we are dealing with individual observations
  // In this case the time-steps can be different from one serie to another

  // allTimeSteps includes all the time-steps during 30 days where a step
  // is every 6 or 24h

  // get all the unique individuals time-steps
  let individualSteps = [];
  for (const serie in series) {
    individualSteps.push(series[serie].data.map(d => d.x));
  }
  individualSteps = Array.from(new Set(individualSteps.flat())).sort();

  // get the period dates that are before and after the range of individuals dates
  // in case the range of individual observations doesn't cover 30 days
  const olderDates = allTimeSteps.filter(d => d < individualSteps[0]);
  const newerDates = allTimeSteps.filter(
    d => d > individualSteps[individualSteps.length - 1]
  );
  // merge the 3 arrays
  return [...olderDates, ...individualSteps, ...newerDates];
};

// `getColorByKey` is used for SYNOP/Quality/Six-hour/Simple
// This the only case where the color
// of a serie in the chart is not static.
// If the property `key` of the object `obj` is equals to `ok`,
// returns the color green, red otherwise.
// This function is executed when creating the chart (in utils/charts.js)
export const getColorByKey = (obj, key, ok) => {
  return obj[key] === ok ? "#1a9850" : "#d73027";
};
