import React from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import * as actions from "../../store/actions/actions";
import { URL_DOWNLOAD_DATA } from "../../utils/api";
import { reverseMapVariablesSlug, allCenters } from "../../utils/general";

import "./DownloadLink.css";

const DownloadLink = props => {
  let prefix = "";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
  }

  const ftype = props[prefix + props.fileType];
  let selectedCountries = props.searchedStations.selectedCountries;
  const clickHandler = event => {
    // Add Google analytics event to track click on 'download data' link
    if (window.location.hostname !== "localhost") {
      ReactGA.event({
        category: "User Interaction",
        action: "Click on download data link",
        label: event.target.getAttribute("href")
      });
    }
  };

  let periodString = "";
  if (ftype.selectedPeriodType === "six_hour") {
    periodString = `&period=${ftype.selectedSixHPeriod}`;
  }
  let variableString = `&variable=${reverseMapVariablesSlug(
    props.fileType,
    ftype.selectedVariable
  )}`;
  // TEMP/six-hour/availability is not by variable
  if (
    props.fileType === "temp" &&
    ftype.selectedPeriodType === "six_hour" &&
    ftype.selectedReport === "availability"
  ) {
    variableString = "";
  }

  let centersString = `&centers=${ftype.selectedCenter}`;
  if (ftype.selectedCenter === "all") {
    centersString = `&centers=${allCenters.join()}`;
    if (ftype.baseline === "GBON") {
      centersString = "&centers=all";
    }
  }

  let baselineString = "";
  // the baseline switch is only displayed for availability (SYNOP)
  // and availability/daily for TEMP
  if (
    (props.fileType === "synop" && ftype.selectedReport === "availability") ||
    (props.fileType === "temp" &&
      // ftype.selectedPeriodType === "daily" &&
      ftype.selectedReport === "availability")
  ) {
    baselineString = `&baseline=${ftype.baseline}`;
  }
  let Countries = "";
  if (selectedCountries !== "") {
    Countries = "&countries=" + selectedCountries;
  }

  let url;

  if (props.fileType === "synop" || props.fileType === "temp") {
    if (ftype.baseline === "GBON") {
      url = `${URL_DOWNLOAD_DATA}/gbon/${props.fileType}/${ftype.selectedPeriodType}/${ftype.selectedReport}/?date=${ftype.selectedDate}${periodString}${variableString}${centersString}${Countries}`;
    } else {
      url = `${URL_DOWNLOAD_DATA}/${props.fileType}/${ftype.selectedPeriodType}/${ftype.selectedReport}/?date=${ftype.selectedDate}${periodString}${variableString}${centersString}${baselineString}${Countries}`;
    }
  } else if (props.fileType === "guan" || props.fileType === "gsn") {
    url = `${URL_DOWNLOAD_DATA}/${props.fileType}/${ftype.selectedReport}/?date=${ftype.selectedDate}${Countries}`;
  } else if (props.fileType === "marine_surface") {
    url = `${URL_DOWNLOAD_DATA}/${props.fileType}/${ftype.selectedPeriodType}/${ftype.selectedReport}/?date=${ftype.selectedDate}${periodString}${variableString}${centersString}${baselineString}${Countries}`;
  }

  return (
    <div className="downloadlink-container">
      <a
        href="https://wmo.int/disclaimer"
        target="_blank"
        className="disclaimer_link"
        rel="noreferrer"
      >
        © WMO
      </a>
      <a href={url} onClick={clickHandler}>
        Download data
      </a>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    guan: state.guan,
    gsn: state.gsn,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface,
    searchedStations: state.searchedStations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchDataFromApi: payload => {
      dispatch(actions.fetchDataFromApi(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadLink);
