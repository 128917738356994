import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DatePicker, Tooltip } from "antd";
import moment from "moment";
import { reports, updateURL, dateNewNCEPSYNOP } from "../../utils/general";
import * as actions from "../../store/actions/actions";

import BaselineSwitch from "../Map/BaselineSwitch/BaselineSwitch";
import SixHourPeriods from "../SixHourPeriods/SixHourPeriods";

import "./MapMenu.css";

const { MonthPicker } = DatePicker;

const MapMenu = props => {
  let prefix = "";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
  }

  const ftype = props[prefix + props.fileType];
  let menuPeriodTypes;
  let menuRest;
  let periodTypeMenu;

  if (props.baseline === "GBON" && props.fileType === "temp") {
    // Remove six hour from the select list
    periodTypeMenu = ftype.periodTypes.map(p => {
      let ret = "";
      if (p === "six_hour") {
        ret = "";
      } else {
        ret = (
          // Capitalize and replace "_" with "-" in period types
          <option key={p}>
            {p.charAt(0).toUpperCase() + p.slice(1).replace("_", "-")}
          </option>
        );
      }
      return ret;
    });
  } else {
    // check if alert and monthly in ftype.periodTypes
    if (
      ftype.periodTypes.includes("alert") &&
      ftype.periodTypes.includes("monthly")
    ) {
      // put alert at the end of the list
      const index = ftype.periodTypes.indexOf("alert");
      ftype.periodTypes.splice(index, 1);
      ftype.periodTypes.push("alert");
    }
    periodTypeMenu = ftype.periodTypes.map(p => {
      return (
        // Capitalize and replace "_" with "-" in period types
        <option key={p}>
          {p.charAt(0).toUpperCase() + p.slice(1).replace("_", "-")}
        </option>
      );
    });
  }
  menuPeriodTypes = (
    <div className="menu-element">
      <label htmlFor="periodtype">Type of Period</label>
      <select
        className="select-css"
        id="menu-periodtype"
        onChange={props.onChangePeriodType}
        value={
          ftype.selectedPeriodType.charAt(0).toUpperCase() +
          ftype.selectedPeriodType.slice(1).replace("_", "-")
        }
      >
        {periodTypeMenu}
      </select>
    </div>
  );
  if (!ftype.periodData[ftype.selectedPeriodType].noData) {
    let arrDates = Object.keys(
      ftype.periodData[ftype.selectedPeriodType].dates[ftype.selectedCenter]
    );
    if (props.fileType === "marine_surface") {
      arrDates = Object.keys(
        ftype.periodData[ftype.selectedPeriodType].dates[ftype.selectedCenter][
          "BUOY"
        ]
      );
    }

    const centersMenu = ftype.periodData[ftype.selectedPeriodType].centers.map(
      c => (
        // Capitalize centers (only necessary for "All")
        <option key={c}>{c.charAt(0).toUpperCase() + c.slice(1)}</option>
      )
    );

    // For now, Alert is only for Quality
    let reportsArray = Object.keys(reports[props.fileType]);
    if (ftype.selectedPeriodType === "alert") {
      reportsArray = ["quality"];
    }
    const reportsMenu = reportsArray.map(r => (
      // Capitalize reports
      <option key={r}>{r.charAt(0).toUpperCase() + r.slice(1)}</option>
    ));

    let optionsVariables;
    // For SYNOP/Quality/NCEP and the selected date is older than 'dateNewNCEPSYNOP'
    // we don't include humidity in the menu
    // of variables. Humidity is the last element in the array:
    // reports[props.fileType][ftype.selectedReport]

    if (
      props.fileType === "synop" &&
      ftype.selectedReport === "quality" &&
      ftype.selectedCenter === "NCEP" &&
      ftype.selectedDate < dateNewNCEPSYNOP
    ) {
      optionsVariables = reports[props.fileType][ftype.selectedReport]
        .slice(0, -1)
        .map(v => {
          return (
            <option key={v.code} value={v.code}>
              {v.name}
            </option>
          );
        });
    } else {
      optionsVariables = reports[props.fileType][ftype.selectedReport].map(
        v => {
          return (
            <option key={v.code} value={v.code}>
              {v.name}
            </option>
          );
        }
      );
    }

    let periodButtons = null;
    if (props.baseline === "GBON" && props.fileType === "temp") {
      periodButtons = null;
    } else if (ftype.selectedPeriodType === "six_hour") {
      periodButtons = (
        <div className="menu-element">
          <SixHourPeriods fileType={props.fileType} baseline={props.baseline} />
        </div>
      );
    }

    let periodDatePicker = null;
    if (ftype.selectedPeriodType === "monthly") {
      const disabledDate = current => {
        return (
          current && !arrDates.includes(moment.utc(current).format("YYYY-MM"))
        );
      };
      // Make the datepicker by month
      periodDatePicker = (
        <MonthPicker
          size="small"
          value={moment.utc(ftype.selectedDate, "YYYY-MM")}
          disabledDate={disabledDate}
          onChange={props.onChangeDate}
          allowClear={false}
        />
      );
    } else {
      const disabledDate = current => {
        return (
          current &&
          !arrDates.includes(moment.utc(current).format("YYYY-MM-DD"))
        );
      };

      periodDatePicker = (
        <DatePicker
          size="small"
          value={moment.utc(
            // props.selectedDate,
            ftype.selectedDate,
            "YYYY-MM-DD"
          )}
          disabledDate={disabledDate}
          onChange={props.onChangeDate}
          allowClear={false}
        />
      );
    }
    let asterisk = null;
    if (
      props.fileType === "synop" &&
      ftype.selectedReport === "availability" &&
      ftype.selectedVariable === "110"
    ) {
      asterisk = (
        <Tooltip
          placement="bottomLeft"
          title={"Also contain geopotential observations"}
        >
          <span className="asterisk">&#x2217;</span>
        </Tooltip>
      );
    }

    let menuVariables = null;
    // No variable menu for TEMP availability
    if (props.fileType !== "temp" || ftype.selectedReport !== "availability") {
      menuVariables = (
        <div className="menu-element">
          <label htmlFor="menu-variables">Variable{asterisk}</label>
          <select
            className="select-css"
            id="menu-variables"
            onChange={props.onUpdateVariable}
            value={ftype.selectedVariable}
          >
            {optionsVariables}
          </select>
        </div>
      );
    }
    let menuReportTypes = null;
    // Only availablity report will exist for GBON
    if (props.baseline === "OSCAR") {
      menuReportTypes = (
        <div className="menu-element">
          <label htmlFor="menu-reports">Monitoring category</label>
          <select
            className="select-css"
            id="menu-reports"
            onChange={props.onChangeReport}
            value={
              ftype.selectedReport.charAt(0).toUpperCase() +
              ftype.selectedReport.slice(1)
            }
          >
            {reportsMenu}
          </select>
        </div>
      );
    }
    let baselineSwitch = null;
    let centerSelecList = null;
    if (
      props.baseline === "OSCAR" ||
      props.fileType === "buoy" ||
      props.fileType === "ship" ||
      props.fileType === "marine_surface"
    ) {
      centerSelecList = (
        <div className="menu-element">
          <label htmlFor="menu-centers">Monitoring Centre</label>
          <select
            className="select-css"
            id="menu-centers"
            onChange={props.onSetCenter}
            value={
              ftype.selectedCenter.charAt(0).toUpperCase() +
              ftype.selectedCenter.slice(1)
            }
          >
            {centersMenu}
          </select>
        </div>
      );
    }
    // let selectedData = filteredData(data, ftype);
    // if (ftype.selectedReport === "availability" && props.baseline !== "GBON") {
    //   baselineSwitch = <BaselineSwitch fileType={props.fileType} />;
    // }
    menuRest = (
      <>
        {menuReportTypes}
        {menuVariables}
        {centerSelecList}
        <div className="menu-element">
          <div>Date</div>
          {periodDatePicker}
        </div>
        {periodButtons}
        {baselineSwitch}
      </>
    );
  } else {
    menuRest = (
      <div className="menu-element">
        <div className="fetch-empty">
          There is no data for the {ftype.selectedPeriodType} periods.
        </div>
      </div>
    );
  }
  // }

  return (
    <>
      <div id="menu">
        {menuPeriodTypes}
        {menuRest}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangePeriodType: event => {
    return dispatch((_, getState) => {
      let prefix = "";
      if (ownProps.baseline === "GBON") {
        prefix = "gbon_";
      }
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      const newPerioTypeValue = event.target.value
        .toLowerCase()
        .replace("-", "_");
      dispatch(
        actions.setProperty({
          type: prefix + ownProps.fileType,
          key: "selectedPeriodType",
          value: newPerioTypeValue
        })
      );

      let objFType = getState()[prefix + ownProps.fileType];
      // If the new period type has data
      if (!objFType.periodData[objFType.selectedPeriodType].noData) {
        // if the selected center has no been set or there is no
        // date for this center for this new periodType (case where
        // there are six-hour periods but no daily period for for a
        // center - this can happen when there is very few periods)
        if (
          !objFType.selectedCenter ||
          !objFType.periodData[objFType.selectedPeriodType].centers.includes(
            objFType.selectedCenter
          )
        ) {
          if (
            objFType.periodData[objFType.selectedPeriodType].centers[0] ===
            "all"
          ) {
            dispatch(
              actions.setProperty({
                type: prefix + ownProps.fileType,
                key: "combinedMode",
                value: true
              })
            );
          }

          dispatch(
            actions.setProperty({
              type: prefix + ownProps.fileType,
              key: "selectedCenter",
              value: objFType.periodData[objFType.selectedPeriodType].centers[0]
            })
          );
          objFType = getState()[prefix + ownProps.fileType];
        }

        // if the selected date has not been set
        // or the date is not available for the current combinaison of parameters
        let centerDates =
          objFType.periodData[objFType.selectedPeriodType].dates[
            objFType.selectedCenter
          ];

        if (ownProps.fileType === "marine_surface") {
          centerDates =
            objFType.periodData[objFType.selectedPeriodType].dates[
              objFType.selectedCenter
            ]["BUOY"];
        }
        let dateCondition = Object.keys(centerDates).includes(
          objFType.selectedDate
        );

        if (!objFType.selectedDate || !dateCondition) {
          dispatch(
            actions.setProperty({
              type: prefix + ownProps.fileType,
              key: "selectedDate",
              value: Object.keys(centerDates)[0]
            })
          );
          objFType = getState()[prefix + ownProps.fileType];
        }
        // if select period type "six-hour" and the six-hour period has
        // not been set (case when the user arrived directly to a daily map)
        if (newPerioTypeValue === "six_hour" && !objFType.selectedSixHPeriod) {
          // get the six-hour periods for this date/center
          const availablePeriods = centerDates[objFType.selectedDate];

          // set "currentSixHPeriods"
          dispatch(
            actions.setProperty({
              type: prefix + ownProps.fileType,
              key: "currentSixHPeriods",
              value: availablePeriods
            })
          );

          // get in an array all the periods that are available
          const arrAvailablePeriods = Object.keys(availablePeriods)
            .sort()
            .filter(p => availablePeriods[p].available);

          // set "selectedSixHPeriod" as the laste lement of the array
          dispatch(
            actions.setProperty({
              type: prefix + ownProps.fileType,
              key: "selectedSixHPeriod",
              value: arrAvailablePeriods[arrAvailablePeriods.length - 1]
            })
          );
          objFType = getState()[prefix + ownProps.fileType];
        }
      }
      // For Alert there is no Availability report (at least for now)
      objFType = getState()[prefix + ownProps.fileType];
      if (
        newPerioTypeValue === "alert" &&
        objFType.selectedReport === "availability"
      ) {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedReport",
            value: "quality"
          })
        );
      }
      objFType = getState()[prefix + ownProps.fileType];
      updateURL(
        ownProps,
        objFType,
        ownProps.fileType,
        "periodType",
        newPerioTypeValue
      );
    });
  },
  onChangeReport: event => {
    return dispatch((_, getState) => {
      let prefix = "";
      if (ownProps.baseline === "GBON") {
        prefix = "gbon_";
      }
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      const newReportValue = event.target.value.toLowerCase();
      dispatch(
        actions.setProperty({
          type: prefix + ownProps.fileType,
          key: "selectedReport",
          value: newReportValue
        })
      );
      let objFType = getState()[prefix + ownProps.fileType];

      // For SYNOP if the selected variable is humidity and center is NCEP
      // and the selected date is older than 'dateNewNCEPSYNOP'
      // and we change the report type to Quality
      // change the variable to surface pressure as we don't
      // display humidity for NCEP/Quality  if date is older than
      // 'dateNewNCEPSYNOP'
      if (
        ownProps.fileType === "synop" &&
        newReportValue === "quality" &&
        objFType.selectedVariable === "58" &&
        objFType.selectedCenter === "NCEP" &&
        objFType.selectedDate < dateNewNCEPSYNOP
      ) {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedVariable",
            value: "110"
          })
        );
      }

      objFType = getState()[prefix + ownProps.fileType];

      // We also have to check if the current variable is available for this new type of report.
      const availableVariableCodes = reports[prefix + ownProps.fileType][
        objFType.selectedReport
      ].map(v => v.code);
      // If it's not we change the variable to the first available for this type of report
      if (!availableVariableCodes.includes(objFType.selectedVariable)) {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedVariable",
            value:
              reports[prefix + ownProps.fileType][objFType.selectedReport][0]
                .code
          })
        );
      }
      objFType = getState()[prefix + ownProps.fileType];
      updateURL(
        ownProps,
        objFType,
        ownProps.fileType,
        "report",
        newReportValue
      );
    });
  },
  onUpdateVariable: event => {
    return dispatch((_, getState) => {
      let prefix = "";
      if (ownProps.baseline === "GBON") {
        prefix = "gbon_";
      }
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      dispatch(
        actions.setProperty({
          type: prefix + ownProps.fileType,
          key: "selectedVariable",
          value: event.target.value
        })
      );
      const objFType = getState()[prefix + ownProps.fileType];
      updateURL(
        ownProps,
        objFType,
        ownProps.fileType,
        "variable",
        event.target.value
      );
    });
  },
  onChangeDate: dateObject => {
    return dispatch((_, getState) => {
      let prefix = "";
      if (ownProps.baseline === "GBON") {
        prefix = "gbon_";
      }
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      /* eslint no-underscore-dangle: 0 */
      const dateString = moment.utc(dateObject).format(dateObject._f);
      dispatch(
        actions.setProperty({
          type: prefix + ownProps.fileType,
          key: "selectedDate",
          value: dateString
        })
      );
      let objFType = getState()[prefix + ownProps.fileType];
      let centerDates =
        objFType.periodData[objFType.selectedPeriodType].dates[
          objFType.selectedCenter
        ];
      let availablePeriods = centerDates[dateString];
      if (ownProps.fileType === "marine_surface") {
        availablePeriods = centerDates["BUOY"][dateString];
      }
      if (objFType.selectedPeriodType === "six_hour") {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "currentSixHPeriods",
            value: availablePeriods
          })
        );

        objFType = getState()[prefix + ownProps.fileType];
        const arrAvailablePeriods = Object.keys(availablePeriods)
          .sort()
          .filter(p => availablePeriods[p].available);

        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedSixHPeriod",
            value: arrAvailablePeriods[arrAvailablePeriods.length - 1]
          })
        );
      }
      objFType = getState()[prefix + ownProps.fileType];

      // For SYNOP if the selected variable is humidity and report type is Quality
      // and the selected date is older than 'dateNewNCEPSYNOP'
      // and the selected center is NCEP, change the variable to surface pressure
      // as we don't display humidity for NCEP/Quality if date is older than
      // 'dateNewNCEPSYNOP'
      if (
        ownProps.fileType === "synop" &&
        objFType.selectedReport === "quality" &&
        objFType.selectedVariable === "58" &&
        objFType.selectedCenter === "NCEP" &&
        objFType.selectedDate < dateNewNCEPSYNOP
      ) {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedVariable",
            value: "110"
          })
        );
      }

      updateURL(ownProps, objFType, ownProps.fileType, "date", dateString);
    });
  },
  onSetCenter: event => {
    return dispatch((_, getState) => {
      let prefix = "";
      if (ownProps.baseline === "GBON") {
        prefix = "gbon_";
      }
      dispatch(
        actions.setProperty({
          type: "searchedStations",
          key: "selectedCountries",
          value: ""
        })
      );
      const newCenterValue =
        event.target.value === "All" ? "all" : event.target.value;
      dispatch(
        actions.setProperty({
          type: prefix + ownProps.fileType,
          key: "selectedCenter",
          value: newCenterValue
        })
      );
      if (newCenterValue === "all") {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "combinedMode",
            value: true
          })
        );
      } else {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "combinedMode",
            value: false
          })
        );
      }
      let objFType = getState()[prefix + ownProps.fileType];

      // For SYNOP if the selected variable is humidity and report type is Quality
      // and the selected date is older than 'dateNewNCEPSYNOP'
      // and we change the center to NCEP, change the variable to surface pressure
      // as we don't display humidity for NCEP/Quality if date is older than
      // 'dateNewNCEPSYNOP'
      if (
        ownProps.fileType === "synop" &&
        objFType.selectedReport === "quality" &&
        objFType.selectedVariable === "58" &&
        newCenterValue === "NCEP" &&
        objFType.selectedDate < dateNewNCEPSYNOP
      ) {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedVariable",
            value: "110"
          })
        );
      }

      objFType = getState()[prefix + ownProps.fileType];
      let availablePeriods = null;
      let centerDates =
        objFType.periodData[objFType.selectedPeriodType].dates[
          objFType.selectedCenter
        ];
      if (ownProps.fileType === "marine_surface") {
        centerDates =
          objFType.periodData[objFType.selectedPeriodType].dates[
            objFType.selectedCenter
          ]["BUOY"];
      }
      // if the date is available for this new selectedCenter
      if (objFType.selectedDate in centerDates) {
        availablePeriods = centerDates[objFType.selectedDate];
      } else {
        // if the date is NOT available for this new selectedCenter
        // select the newest one available for this selectedCenter
        let newestDate = null;
        if (objFType.selectedPeriodType === "six_hour") {
          newestDate = Object.keys(centerDates)[0];
        } else {
          newestDate = Object.keys(centerDates)[0];
        }
        dispatch(
          // actions.setSelectedDate(newestDate)
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedDate",
            value: newestDate
          })
        );
        // currentState = getState();
        objFType = getState()[prefix + ownProps.fileType];
        // get the six hour period for the selected date
        availablePeriods = centerDates[newestDate];
      }
      if (objFType.selectedPeriodType === "six_hour") {
        dispatch(
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "currentSixHPeriods",
            value: availablePeriods
          })
        );
        // currentState = getState();
        objFType = getState()[prefix + ownProps.fileType];
        let period = null;
        // if the current selected six hour-period is available
        if (availablePeriods[objFType.selectedSixHPeriod].available) {
          period = objFType.selectedSixHPeriod;
        } else {
          // if the current selected six hour-period is NOT available
          // we take the first available one for this date
          period = Object.keys(availablePeriods)
            .sort()
            .filter(p => availablePeriods[p].available)[0];
        }
        dispatch(
          // actions.setSelectedSixHPeriod(period)
          actions.setProperty({
            type: prefix + ownProps.fileType,
            key: "selectedSixHPeriod",
            value: period
          })
        );
      }
      objFType = getState()[prefix + ownProps.fileType];
      updateURL(
        ownProps,
        objFType,
        ownProps.fileType,
        "center",
        newCenterValue
      );
    });
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapMenu)
);
