import React from "react";
import ReactGA from "react-ga";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AppMenu from "./components/Menu/Menu";

import Router from "../src/router";

import "./App.css";

const App = props => {
  // Google Analytics minimal setup
  // Those 3 tracking codes are WMO's
  if (window.location.hostname !== "localhost") {
    if (window.location.hostname.includes("dev")) {
      ReactGA.initialize("G-LKBVCDSCHH");
    } else if (window.location.hostname.includes("test")) {
      ReactGA.initialize("G-69VGNEHTEW");
    } else {
      // PROD
      ReactGA.initialize("G-VF9MQE5EKY");
    }
  }

  return (
    <div className="level1">
      <Header />
      <AppMenu />
      <main className="wdqms-content">
        <Router />
      </main>
      <Footer />
    </div>
  );
};
export default App;
