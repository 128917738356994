import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../store/actions/actions";
import { reverseMapVariablesSlug } from "../../utils/general";

import { Spin, Alert } from "antd";
import Map from "../Map/Map";
import MapMarine from "../Map/MapMarine";
import MapMenu from "../MapMenu/MapMenu";
import GCOSMapMenu from "../GCOS/MapMenu/MapMenu";
import ErrorParamsURL from "../ErrorParamsURL/ErrorPramsURL";
import DownloadLink from "../DownloadLink/DownloadLink";

import { isEmpty } from "lodash";

import "antd/lib/spin/style/index.css";
import "./MapContainer.css";

const MapContainer = props => {
  let prefix = "";
  let pathPrefix = "nwp";
  if (props.baseline === "GBON") {
    prefix = "gbon_";
    pathPrefix = "gbon";
  }
  const ftype = props[prefix + props.fileType];
  let history = useHistory();
  let [titleMap, setTitleMap] = useState(null);
  let [subTitleMap, setSubTitleMap] = useState(null);

  let [errorParamsURL, setErrorParamsURL] = useState(false);

  let isPageMonitored = false;

  const changeURL = () => {
    const variableName = reverseMapVariablesSlug(
      props.fileType,
      ftype.selectedVariable
    );
    if (props.fileType === "synop") {
      if (ftype.selectedPeriodType === "six_hour") {
        history.push(
          `/${pathPrefix}/land_surface/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}/${ftype.selectedSixHPeriod}`
        );
      } else if (
        ftype.selectedPeriodType === "daily" ||
        ftype.selectedPeriodType === "monthly" ||
        ftype.selectedPeriodType === "alert"
      ) {
        history.push(
          `/${pathPrefix}/land_surface/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}`
        );
      }
    } else if (props.fileType === "temp") {
      if (ftype.selectedPeriodType === "six_hour") {
        if (ftype.selectedReport === "availability") {
          if (props.baseline === "GBON") {
            history.push(
              `/${pathPrefix}/land_upper-air/daily/${ftype.selectedReport}/${ftype.selectedCenter}/${ftype.selectedDate}`
            );
            window.location.href = `/${pathPrefix}/land_upper-air/daily/${ftype.selectedReport}/${ftype.selectedCenter}/${ftype.selectedDate}`;
          } else {
            // 'variableName' is NOT part of the URL
            history.push(
              `/${pathPrefix}/land_upper-air/${ftype.selectedPeriodType}/${ftype.selectedReport}/${ftype.selectedCenter}/${ftype.selectedDate}/${ftype.selectedSixHPeriod}`
            );
          }
        } else {
          // quality - 'variableName' is part of the URL
          history.push(
            `/${pathPrefix}/land_upper-air/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}/${ftype.selectedSixHPeriod}`
          );
        }
      } else if (
        ftype.selectedPeriodType === "daily" ||
        ftype.selectedPeriodType === "monthly"
      ) {
        if (ftype.selectedReport === "availability") {
          // 'variableName' is NOT part of the URL
          history.push(
            `/${pathPrefix}/land_upper-air/${ftype.selectedPeriodType}/${ftype.selectedReport}/${ftype.selectedCenter}/${ftype.selectedDate}`
          );
        } else {
          // quality - 'variableName' is part of the URL
          history.push(
            `/${pathPrefix}/land_upper-air/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}`
          );
        }
      }
    } else if (props.fileType === "guan") {
      if (ftype.selectedReport === "availability") {
        history.push(
          `/gcos/land_upper-air/${ftype.selectedReport}/${ftype.selectedDate}`
        );
      } else {
        history.push(
          `/gcos/land_upper-air/${ftype.selectedReport}/${ftype.selectedVariable}/${ftype.selectedDate}`
        );
      }
    } else if (props.fileType === "gsn") {
      if (ftype.selectedReport === "availability") {
        history.push(
          `/gcos/land_surface/${ftype.selectedReport}/${ftype.selectedDate}`
        );
      } else {
        // Completeness
        history.push(
          `/gcos/land_surface/${ftype.selectedReport}/${ftype.selectedVariable}/${ftype.selectedDate}`
        );
      }
    } else if (props.fileType === "buoy" || props.fileType === "ship") {
      if (ftype.selectedPeriodType === "six_hour") {
        history.push(
          `/nwp/${props.fileType}/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}/${ftype.selectedSixHPeriod}`
        );
      } else if (
        ftype.selectedPeriodType === "daily" ||
        ftype.selectedPeriodType === "monthly"
      ) {
        history.push(
          `/nwp/${props.fileType}/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}`
        );
      }
    } else if (props.fileType === "marine_surface") {
      if (ftype.selectedPeriodType === "six_hour") {
        history.push(
          `/nwp/${props.fileType}/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}/${ftype.selectedSixHPeriod}`
        );
      } else if (
        ftype.selectedPeriodType === "daily" ||
        ftype.selectedPeriodType === "monthly"
      ) {
        history.push(
          `/nwp/${props.fileType}/${ftype.selectedPeriodType}/${ftype.selectedReport}/${variableName}/${ftype.selectedCenter}/${ftype.selectedDate}`
        );
      }
    }
    if (window.location.hostname !== "localhost") {
      // This is to prevent from tracking the page twice
      // as in some cases (when revisiting the /nwp page from
      // another page of the app)
      // the useEffects containing the function 'changeURL'
      // are both called - TO IMPROVE
      if (!isPageMonitored) {
        // Google Analytics
        // Update the user's current page
        ReactGA.set({
          page: window.location.pathname
        });
        // Record a pageview for the given page
        ReactGA.pageview(window.location.pathname);
        isPageMonitored = true;
      }
    }
  };

  useEffect(() => {
    let dataType;
    let gbonSubTitle = "";
    const gbonSubTitleText = "";
    if (props.fileType === "synop" && props.baseline === "OSCAR") {
      dataType = "surface land observations (global NWP)";
    } else if (props.fileType === "temp" && props.baseline === "OSCAR") {
      dataType = "upper-air land observations (global NWP)";
    } else if (props.fileType === "gsn") {
      dataType = "surface land observations (GCOS)";
    } else if (props.fileType === "guan") {
      dataType = "upper-air land observations (GCOS)";
    } else if (props.fileType === "synop" && props.baseline === "GBON") {
      dataType = "surface land observations (GBON)";
      gbonSubTitle = gbonSubTitleText;
    } else if (props.fileType === "temp" && props.baseline === "GBON") {
      dataType = "upper-air land observations (GBON)";
      gbonSubTitle = gbonSubTitleText;
    } else if (props.fileType === "buoy") {
      dataType = "Surface Marine observations - Buoy";
    } else if (props.fileType === "ship") {
      dataType = "Surface Marine observations - Ship";
    } else if (props.fileType === "marine_surface") {
      dataType = "Marine Surface observations";
    }
    const report =
      ftype.selectedReport.charAt(0).toUpperCase() +
      ftype.selectedReport.slice(1);
    setTitleMap(`${report} of ${dataType}`);
    setSubTitleMap(`${gbonSubTitle}`);
  }, [ftype.selectedReport, props.fileType, props.baseline]);

  useEffect(() => {
    // Before fetching the data from the API
    // check that URL parameters make sense

    const centers_choices = ["all", "DWD", "ECMWF", "JMA", "NCEP"];
    const periods_choices = ["00", "06", "12", "18"];
    const periodTypesSYNOP_choices = ["six_hour", "daily", "alert", "monthly"];
    const reports_choices = ["availability", "quality"];
    const variablesSYNOP_choices = [
      "pressure",
      "temperature",
      "zonal_wind",
      "meridional_wind",
      "humidity",
      "geopotential"
    ];

    const periodTypesTEMP_choices = ["six_hour", "daily", "monthly"];
    const variablesTEMP_choices = [
      "temperature",
      "zonal_wind",
      "meridional_wind",
      "humidity"
    ];
    // Catch the URLs that are not correct
    if (
      !isEmpty(props.params) &&
      props.fileType === "synop" &&
      (!centers_choices.includes(props.params.center) ||
        (props.params.periodType === "six_hour" &&
          !periods_choices.includes(props.params.period)) ||
        !periodTypesSYNOP_choices.includes(props.params.periodType) ||
        !reports_choices.includes(props.params.report) ||
        !variablesSYNOP_choices.includes(props.params.variable))
    ) {
      setErrorParamsURL(true);
    } else if (
      !isEmpty(props.params) &&
      props.fileType === "temp" &&
      (!centers_choices.includes(props.params.center) ||
        (props.params.periodType === "six_hour" &&
          !periods_choices.includes(props.params.period)) ||
        !periodTypesTEMP_choices.includes(props.params.periodType) ||
        (props.fileType === "temp" &&
          props.params.variable &&
          !variablesTEMP_choices.includes(props.params.variable)))
    ) {
      setErrorParamsURL(true);
    } else {
      let isAlreadyFetched = props[props.fileType].dataFetched;
      if (
        (props.fileType === "synop" || props.fileType === "temp") &&
        props.baseline === "OSCAR"
      ) {
        props.onFetchDataFromApi({
          baseline: props.baseline,
          fileType: props.fileType,
          alreadyFetched: isAlreadyFetched,
          params: props.params
        });
      } else if (
        (props.fileType === "synop" || props.fileType === "temp") &&
        props.baseline === "GBON"
      ) {
        isAlreadyFetched = props["gbon_" + props.fileType].dataFetched;
        props.onFetchGbonDataFromApi({
          baseline: props.baseline,
          fileType: "gbon_" + props.fileType,
          parentFileType: props.fileType, // either synop or temp
          alreadyFetched: isAlreadyFetched,
          params: props.params
        });
      } else if (props.fileType === "guan" || props.fileType === "gsn") {
        props.onFetchGCOSDataFromApi({
          fileType: props.fileType,
          alreadyFetched: isAlreadyFetched,
          params: props.params
        });
      } else if (props.fileType === "buoy" || props.fileType === "ship") {
        props.onFetchMarineDataFromApi({
          fileType: props.fileType,
          alreadyFetched: isAlreadyFetched,
          params: props.params
        });
      } else if (props.fileType === "marine_surface") {
        props.onFetchBuoyShipMarineDataFromApi({
          fileType: props.fileType,
          alreadyFetched: isAlreadyFetched,
          params: props.params
        });
      }

      if (ftype.readyToFillMenu) {
        changeURL();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fileType, props.baseline]);

  useEffect(
    () => {
      if (ftype.readyToFillMenu) {
        changeURL();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [ftype.readyToFillMenu],
    props.baseline
  );

  let content = <Spin className="spinner-level2" tip="Loading..." />;

  if (errorParamsURL || props.error404) {
    content = <ErrorParamsURL />;
  }

  let mapmenu;
  if (
    props.fileType === "synop" ||
    props.fileType === "temp" ||
    props.fileType === "buoy" ||
    props.fileType === "ship" ||
    props.fileType === "marine_surface"
  ) {
    mapmenu = <MapMenu fileType={props.fileType} baseline={props.baseline} />;
  } else if (props.fileType === "guan" || props.fileType === "gsn") {
    mapmenu = <GCOSMapMenu fileType={props.fileType} />;
    // We don't show this message for SYNOP or TEMP as we can
    // have data for a period type (e.g six-hour) but no data
    // for another (e.g daily or monthly)
    // For GUAN and GSN it's simpler as there is no period type
    if (ftype.noData) {
      content = (
        <Alert
          className="alert-no-data"
          message="Information"
          description={`The ${props.fileType.toUpperCase()} data is currently being uploaded. Please retry in a few minutes.`}
          type="info"
          showIcon
        />
      );
    }
  }
  if (ftype.readyToFillMenu && !props.error404) {
    if (props.fileType === "marine_surface") {
      content = (
        <>
          <div className="title-map">{titleMap}</div>
          <span className="subtitle-map">{subTitleMap}</span>
          {mapmenu}
          <MapMarine fileType={props.fileType} baseline={props.baseline} />
          <DownloadLink fileType={props.fileType} baseline={props.baseline} />
        </>
      );
    } else {
      content = (
        <>
          <div className="title-map">{titleMap}</div>
          <span className="subtitle-map">{subTitleMap}</span>
          {mapmenu}
          <Map fileType={props.fileType} baseline={props.baseline} />
          <DownloadLink fileType={props.fileType} baseline={props.baseline} />
        </>
      );
    }
  }
  return <div className="level2">{content}</div>;
};

const mapStateToProps = state => {
  return {
    synop: state.synop,
    temp: state.temp,
    guan: state.guan,
    gsn: state.gsn,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface,
    error404: state.error404
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchDataFromApi: payload => {
      dispatch(actions.fetchDataFromApi(payload, ""));
    },
    onFetchGbonDataFromApi: payload => {
      dispatch(actions.fetchGbonDataFromApi(payload, ""));
    },
    onFetchGCOSDataFromApi: payload => {
      dispatch(actions.fetchGCOSDataFromApi(payload));
    },
    onFetchMarineDataFromApi: payload => {
      dispatch(actions.fetchMarineDataFromApi(payload, ""));
    },
    onFetchBuoyShipMarineDataFromApi: payload => {
      dispatch(actions.fetchBuoyShipMarineDataFromApi(payload, ""));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
